/* Header */
header .logo img {
    max-height: 40px;
}
header .user-menu-wrapper {
    margin-top: 16px;
}
/* Colors */
.btn-link,
a {
    color: #666666;
}
.btn-link:hover,
a:hover {
    color: #666666;
}
.btn-primary.disabled,
.btn-primary:disabled {
    background-color: #666666;
}
.dropdown-item.active,
.dropdown-item:active,
a.text-danger.dropdown-item.active:focus,
a.text-danger.dropdown-item:active:hover {
    background-color: #666666;
    color: #FFFFFF !important;
}
/* Select2 */
.field_select2 .select2 {
    width: 100% !important;
    max-width: 350px;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #666666 !important;
}
/* FlatPickr */
.field_datepicker input.form-control[readonly],
.field_datetimepicker input.form-control[readonly],
.field_timepicker input.form-control[readonly] {
    background-color: #FFFFFF;
}
.nullable-control {
    display: none;
}
/* Vich File Input */
.input-file-container,
.input-file-container:active,
.input-file-container:active:focus,
.input-file-container:focus,
.input-file-container:hover {
    margin: 1px !important;
}
.input-file-container .btn-label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0 !important;
}
.input-file-container .btn-label .custom-file-label {
    margin: 0;
    background: none;
    border: 0;
}
.input-file-container .btn-label .custom-file-label::after {
    content: none;
    background: none;
}